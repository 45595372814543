<template>
  <b-card no-body>
    <b-card-header class="pb-50 justify-content-between">
      <h5>フィルター</h5>
      <b-button variant="primary" @click="getAll">検索</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="開始日:">
            <b-form-datepicker v-model="form.startDate" locale="ja-JP"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" width="100">
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="終了日:">
            <b-form-datepicker v-model="form.endDate" locale="ja-JP"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" width="100">
            </b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="会社domainフィルター">
            <b-form-input v-model="form.companyEmail"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" class="my-1">
          <b-button variant="primary" @click="exportExcel">Export</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12">
          <b-table :items="items" :fields="fields" :busy="isBusy" :responsive="true" :current-page="currentPage"
            :per-page="perPage" outlined>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" sm="6" class="
              d-flex
              align-items-center
              justify-content-start 
            ">
          合計: {{ totalRows }}
        </b-col>
        <b-col cols="6" sm="6" class="
              d-flex
              align-items-center
              justify-content-center
            ">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
            class="mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
  BSpinner,
  BFormDatepicker,
  BFormCheckbox,
  BFormSelect,
  BFormFile,
  BFormTextarea,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import moment from "moment";
import MultiSelect from "primevue/multiselect";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {utils, writeFile} from 'xlsx/xlsx.mjs';

export default {
  components: {
    BForm,
    BFormTextarea,
    BFormDatepicker,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BSpinner,
    BCardHeader,
    BCardBody,
    MultiSelect
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      form: {
        startDate: null,
        endDate: null,
        companyEmail: ""
      },
      isBusy: false,
      fields: [
        {
          key: 'index',
          label: "No."
        },
        {
          key: 'commandName',
          label: "コマンド名"
        },
        {
          key: 'dateTime',
          label: "使用時期"
        },
        {
          key: 'email',
          label: "MFTools ログインメール"
        },
        {
          key: 'revitVersion',
          label: "Revit Version"
        }
      ],
      items: []
    }
  },
  async created() {
    const dateNow = new Date();
    this.form.startDate = moment(dateNow).subtract(10, 'days').toDate();
    this.form.endDate = dateNow;

    await this.getAll();

  },
  methods: {
    exportExcel() {
      let finalHeaders = this.fields.map(x => x.label);
      let index = 1;
      let data = this.items.map(x => ({
        "No.": index++,
        "コマンド名": x.commandName,
        "使用時期": x.dateTime,
        "MFTools ログインメール": x.email,
        "Revit Version": x.revitVersion
      }));

      let wb = utils.book_new()
      let ws = utils.json_to_sheet(data, { header: finalHeaders });
      utils.book_append_sheet(wb, ws, "SheetJS")
      let exportFileName = `workbook.csv`;
      writeFile(wb, exportFileName, {boolType: "csv"})
    },

    async getAll() {
      const diffDate = moment(this.form.endDate).diff(moment(this.form.startDate), 'days');

      if (diffDate > 30) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "Thoi gian khong qua 30 ngay",
            variant: "danger",
            solid: true,
          },
        });
        return;
      }

      if (!this.form.companyEmail.trim()) {
        return;
      }

      this.isBusy = true;
      const payload = { ...this.form };
      payload.groupBy = 2;
      const res = await axios.post("UserCommandHistory", payload);
      let index = 0;
      this.items = res.data.data.map(x => {
        index++;
        const metaData = JSON.parse(x.description);
        return {
          ...x,
          index: index % 10 === 0 ? 10 : index % 10,
          dateTime: moment(x.dateTime).format("YYYY/MM/DD HH:mm:ss"),
          revitVersion: metaData.RevitVersion
        }
      });
      this.totalRows = this.items.length;
      this.isBusy = false;
    }
  }
}
</script>
