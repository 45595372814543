<template>
  <b-card no-body>
    <b-card-header class="pb-50 justify-content-between">
      <h5>フィルター</h5>
      <b-button variant="primary" @click="getStatisticCommand">検索</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="開始日:">
            <b-form-datepicker v-model="form.startDate" locale="ja-JP"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              width="100"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="終了日:">
            <b-form-datepicker v-model="form.endDate" locale="ja-JP"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              width="100"></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="コマンド数表示:">
            <b-form-select v-model="form.limit" :options="limits"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group label="コマンドリスト:">
            <div class="card flex justify-center">
              <MultiSelect v-model="selectedCommands" :options="commands" optionLabel="name" filter display="chip"
                class="w-full md:w-80">
                <!-- <template #option="slotProps">
                  <div class="flex items-center">
                    <div>{{ slotProps.option.name }}</div>
                  </div>
                </template>
<template #footer>
                  <div class="py-2 px-4">
                    <b>{{ selectedCommands ? selectedCommands.length : 0 }}</b> item{{ (selectedCommands ?
                      selectedCommands.length : 0) > 1 ? 's' : '' }} selected.
                  </div>
                </template> -->
              </MultiSelect>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" class="my-1">
        <b-button variant="primary" @click="exportExcel">Export</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12">
          <vue-apex-charts height="500" type="line" :options="chartOptions" :series="series"></vue-apex-charts>
        </b-col>
      </b-row>

      <b-table :items="items" :fields="fields" :busy="isBusy" :responsive="true" outlined>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>



    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
  BSpinner,
  BFormDatepicker,
  BFormCheckbox,
  BFormSelect,
  BFormFile,
  BFormTextarea,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import moment from "moment";
import MultiSelect from "primevue/multiselect";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueApexCharts from 'vue-apexcharts'
import {utils, writeFile} from 'xlsx/xlsx.mjs';

export default {
  components: {
    VueApexCharts,
    BForm,
    BFormTextarea,
    BFormDatepicker,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BSpinner,
    BCardHeader,
    BCardBody,
    MultiSelect
  },
  data() {
    return {
      series: [{
        name: "Số lượng",
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          width: "100%",
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        yaxis: {
          title: {
            text: 'Temperature'
          },
          min: 0,
          max: 400
        },
      },
      form: {
        startDate: null,
        endDate: null,
        limit: 0
      },
      selectedCommands: [],
      commands: [],
      isBusy: false,
      fields: [
        {
          key: 'index',
          label: "No."
        },
        {
          key: 'commandName',
          label: "コマンド名"
        },
        {
          key: 'total',
          label: "使用数"
        }
      ],
      items: [],
      limits: [
        { value: 0, text: '全て' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
      ],
      maxChartCategory: 20
    }
  },
  watch: {
    items(val) {
      if (val) {
        this.displayChart();
      }
    }
  },
  async created() {
    const dateNow = new Date();
    this.form.startDate = moment(dateNow).subtract(10, 'days').toDate();
    this.form.endDate = dateNow;
    this.form.limit = 0;
    await this.getAllCommand();
    await this.getStatisticCommand();
    this.displayChart();
  },
  methods: {
    exportExcel() {
      let finalHeaders = this.fields.map(x => x.label);
      let data = this.items.map(x => ({
        "No.": x.index,
        "コマンド名": x.commandName,
        "使用数": x.total
      }));

      let ws = utils.json_to_sheet(data, { header: finalHeaders });
      let wb = utils.book_new()
      utils.book_append_sheet(wb, ws, "SheetJS")
      let exportFileName = `workbook.csv`;
      writeFile(wb, exportFileName, {boolType: "csv"})
    },
    async getAllCommand() {
      let res = await axios.get(`CommandTool/all`);
      let commands = res.data.data;
      this.commands = commands;
    },
    async getStatisticCommand() {
      const diffDate = moment(this.form.endDate).diff(moment(this.form.startDate), 'days');

      if (diffDate > 30) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "Thoi gian khong qua 30 ngay",
            variant: "danger",
            solid: true,
          },
        });
        return;
      }
      this.isBusy = true;
      const payload = { ...this.form };
      payload.groupBy = 1;
      payload.commandIds = this.selectedCommands.map(x => x.id);
      const res = await axios.post("UserCommandHistory", payload);
      let index = 1;
      this.items = res.data.data.map(x => { return { ...x, index: index++ } });
      this.isBusy = false;
    },

    displayChart() {
      this.series = [{
        name: "コマンド数",
        data: this.items.map(x => {
          return { x: x.commandName, y: x.total };
        }).slice(0, this.maxChartCategory)
      }];

      this.chartOptions = {
        ...this.chartOptions, ...{
          yaxis: {
            max: Math.max(...this.items.map(x => x.total).slice(0, this.maxChartCategory)),
            min: Math.min(...this.items.map(x => x.total).slice(0, this.maxChartCategory)),
          },
          xaxis: {
            labels:{
              rotate: -90,
              rotateAlways: true,
              hideOverlappingLabels: false,
            }
          }
        }
      };
    }
  }
}
</script>
