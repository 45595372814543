<template>
  <section id="dashboard-analytics">
    <b-tabs content-class="mt-3">
      <b-tab title="コマンド集計" active>
        <Command />
      </b-tab>
      <b-tab title="ユーザー集計">
        <User />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import Command from './Command.vue';
import User from './User.vue';
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    Command,
    User
  },
  data() {
    return {
      data: {},
    }
  },
  created() {

  },
  methods: {
  },
}
</script>
