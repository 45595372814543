export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/charts-and-maps/charts/apex-chart/ApexChart.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/tool-analytics',
    name: 'statistics-cmd-history',
    component: () => import('@/views/dashboard/tool-analytics/Index.vue'),
  },
]
