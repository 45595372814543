export default [
  {
    header: "Admin",
  },
  // {
  //   title: "Profile",
  //   route: "user-profile",
  //   icon: "UserIcon",
  //   action: 'read'
  // },
  // {
  //   title: "Config",
  //   route: "admin-configs",
  //   icon: "SettingsIcon",
  // },
  {
    title: "Users",
    route: "admin-users",
    icon: "UsersIcon",
  },
  {
    title: "Licensing",
    route: "admin-user-license",
    icon: "BookmarkIcon",
  },
  // {
  //   title: "User Logs",
  //   route: "user-log",
  //   icon: "UsersIcon",
  // },
  {
    title: "Admin Logs",
    route: "admin-logs",
    icon: "Edit2Icon",
  },

  {
    title: "Analytics",
    route: "statistics-cmd-history",
    icon: "HomeIcon",
  },

  // {
  //   title: "Permission",
  //   route: "admin-permissions",
  //   icon: "EditIcon",
  // },
  // {
  //   title: "Roles & Permissions",
  //   route: "roles-permissions",
  //   icon: "EditIcon",
  // },
  // {
  //   title: "Setting",
  //   route: "setting",
  //   icon: "SettingsIcon",
  // },
];
